import React from 'react'
import Layout from '../../../../sea-site-theme/src/components/Layout'
import SEO from '../../../../sea-site-theme/src/components/SEO'
import { graphql } from 'gatsby'
import Hero from '../../components/dolphin-commercial/hero'
import PageDescription from '../../components/dolphin-commercial/page-description'
import Products from '../../components/dolphin-commercial/products'
import TwoBanner from '../../components/dolphin-commercial/two-banner'
import Specifications from '../../components/dolphin-commercial/specifications'
import QuoteRequest from '../../components/dolphin-commercial/quote-request'
import Poseidon from '../../components/dolphin-commercial/poseidon'

interface HeroProp {
	node: {
		seoTitle: string
		seoKeywords: string[]
		seoDescription: string
		body: {
			raw: string
		}
		media: {
			file?: {
				url?: string
			}
			description?: string
		}
		banner: {
			mainActionText: string
			mainActionUrl: string
			secondActionText: string
			secondActionUrl: string
			description: {
				raw: string
			}
			videoBanner: {
				file?: {
					url?: string
				}
			}
		}
	}
}

export interface Items {
	node: {
		active: boolean
		brand: string
		categories: {
			title: string
			slug: string
		}
		images: {
			description: string
			fluid: {
				src: string
				srcWebp: string
			}
		}[]
		model: string
		price: number
		seoDescription: string
		seoTitle: string
		shippingClass: string
		sku: string
		slug: string
		series: string
		node_locale: string
	}
}

export interface PoseidonType {
	backgroundImage: {
		file: {
			url: string
		}
	}
	description: {
		raw: string
	}
	image: {
		description: string
		file: {
			url: string
		}
	}
	mainActionText: string
	mainActionUrl: string
	node_locale: string
}

export interface StatsTypes {
	node: {
		node_locale: string
		title: string
		items: {
			name: string
			body: {
				raw: string
			}
		}[]
	}
}

export interface PowerHouseProp {
	description: {
		raw: string
	}
	node_locale: string
}
export interface WhichModelProp {
	description: {
		raw: string
	}
	mainActionText: string
	mainActionUrl: string
	node_locale: string
}
export interface SpecsProps {
	node: {
		node_locale: string
		title: string
		items: {
			name: string
		}[]
	}
}

interface Props {
	data: {
		commercial: {
			edges: HeroProp[]
		}
		bannerLgImage: {
			edges: {
				node: {
					backgroundImage: {
						fluid: {
							src: string
							srcWebp: string
						}
						node_locale: string
					}
				}
			}[]
		}
		bannerSmImage: {
			edges: {
				node: {
					backgroundImage: {
						fluid: {
							src: string
							srcWebp: string
						}
						node_locale: string
					}
				}
			}[]
		}
		productImage: {
			edges: Items[]
		}
		poseidon: {
			edges: {
				node: PoseidonType
			}[]
		}
		stats: {
			edges: StatsTypes[]
		}
		powerhouse: {
			edges: {
				node: PowerHouseProp
			}[]
		}
		whichmodel: {
			edges: {
				node: WhichModelProp
			}[]
		}
		specifications: {
			edges: SpecsProps[]
		}
	}
}

const Commercial = ({
	data: {
		commercial,
		bannerLgImage,
		productImage,
		powerhouse,
		whichmodel,
		specifications,
		poseidon,
		stats,
		bannerSmImage,
	},
}: Props): JSX.Element => {
	const locale = 'th-TH'
	const [{ node }] = commercial.edges
	const [{ node: bannerlg }] = bannerLgImage.edges
	const [{ node: bannersm }] = bannerSmImage.edges
	const [{ node: powerHouse }] = powerhouse.edges
	const [{ node: whichModel }] = whichmodel.edges
	const [{ node: commercialposeidon }] = poseidon.edges

	const filterAllByLocale = (items: StatsTypes[] | SpecsProps[], locale: string) => {
		return items.filter((item) => {
			return item.node.node_locale === locale
		})
	}
	const specs = filterAllByLocale(specifications.edges, locale)
	const poseidonStats = filterAllByLocale(stats.edges, locale)

	const {
		seoTitle,
		seoKeywords,
		seoDescription,
		body: pagebody,
		media: brandUrl,
		banner: { mainActionText, mainActionUrl, secondActionText, secondActionUrl, description, videoBanner: videourl },
	} = node

	const bannerSources = [
		{
			...bannerlg.backgroundImage.fluid,
			srcSet: `${bannerlg.backgroundImage.fluid.src} 1920w`,
			srcSetWebp: `${bannerlg.backgroundImage.fluid.srcWebp} 1920w`,
			media: `(min-width: 1350px)`,
		},
		{
			...bannersm.backgroundImage.fluid,
			srcSet: `${bannersm.backgroundImage.fluid.src} 524w`,
			srcSetWebp: `${bannersm.backgroundImage.fluid.srcWebp} 524w`,
			media: `(max-width: 524px)`,
		},
	]

	return (
		<Layout>
			<SEO title={seoTitle} keywords={seoKeywords} description={seoDescription} />
			<Hero
				{...{
					mainActionText,
					mainActionUrl,
					secondActionText,
					secondActionUrl,
					description,
					videourl,
					brandUrl,
					bannerSources,
				}}
			/>
			<PageDescription pagebody={pagebody} />
			<Products locale={locale} productImage={productImage} />
			<TwoBanner powerhouse={powerHouse} whichmodel={whichModel} />
			<Specifications specs={specs} />
			<QuoteRequest locale={locale} />
			{/* <Poseidon poseidon={commercialposeidon} stats={poseidonStats as StatsTypes[]} /> */}
		</Layout>
	)
}

export const commercialQuery = graphql`
	query commercialQuery {
		commercial: allContentfulPage(filter: { contentful_id: { eq: "7qxCG7B9uDecvQEKmVk9z5" } }) {
			edges {
				node {
					seoTitle
					seoKeywords
					seoDescription
					slug
					node_locale
					body {
						raw
					}
					media {
						description
						file {
							url
						}
					}
					banner {
						mainActionText
						mainActionUrl
						secondActionText
						secondActionUrl
						description {
							raw
						}
						videoBanner {
							file {
								url
							}
						}
					}
				}
			}
		}
		bannerLgImage: allContentfulPage(filter: { contentful_id: { eq: "7qxCG7B9uDecvQEKmVk9z5" } }) {
			edges {
				node {
					backgroundImage {
						fluid(maxWidth: 1920, maxHeight: 1080, quality: 60, cropFocus: CENTER, resizingBehavior: FILL) {
							src
							srcWebp
						}
					}
					node_locale
				}
			}
		}
		bannerSmImage: allContentfulPage(filter: { contentful_id: { eq: "7qxCG7B9uDecvQEKmVk9z5" } }) {
			edges {
				node {
					backgroundImage {
						fluid(maxWidth: 524, maxHeight: 300, quality: 60, cropFocus: CENTER, resizingBehavior: FILL) {
							src
							srcWebp
						}
					}
					node_locale
				}
			}
		}
		productImage: allContentfulProduct {
			edges {
				node {
					node_locale
					seoTitle
					seoDescription
					slug
					sku
					price
					model
					brand
					active
					series
					categories {
						title: seoTitle
						slug
					}
					shippingClass
					images {
						fluid(maxWidth: 600, maxHeight: 400, quality: 60) {
							src
							srcWebp
						}
						description
					}
				}
			}
		}
		powerhouse: allContentfulBanner(filter: { contentful_id: { eq: "4KeP8UZlcr4fLKuHWYaAml" } }) {
			edges {
				node {
					node_locale
					description {
						raw
					}
				}
			}
		}
		whichmodel: allContentfulBanner(filter: { contentful_id: { eq: "31h1HfMKcA3QOLdqkQEEu4" } }) {
			edges {
				node {
					node_locale
					description {
						raw
					}
					mainActionText
					mainActionUrl
				}
			}
		}
		specifications: allContentfulFeaturedItems(
			filter: { contentful_id: { in: ["1jHYDiMUZL1k0qHlJh2KGZ", "6Ve9fEYGgXTysG8sBclGjS", "3ranBcVikXq1glmLDiCH40"] } }
		) {
			edges {
				node {
					title
					node_locale
					items {
						name
					}
				}
			}
		}
		poseidon: allContentfulBanner(filter: { contentful_id: { eq: "4xxarRlorH1lpjQaTdEZBe" } }) {
			edges {
				node {
					node_locale
					description {
						raw
					}
					image {
						description
						file {
							url
						}
					}
					backgroundImage {
						file {
							url
						}
					}
					mainActionText
					mainActionUrl
				}
			}
		}
		stats: allContentfulFeaturedItems(filter: { contentful_id: { eq: "34wwfqboQZWzz76KieBxns" } }) {
			edges {
				node {
					title
					node_locale
					items {
						name
						body {
							raw
						}
					}
				}
			}
		}
	}
`

export default Commercial
